<template>
    <div class="tw-flex tw-items-center tw-gap-4">
        <template v-if="!is_store_wholesaler">
            <span
                class="tw-text-primary-800 lg:tw-text-xl tw-font-bold tw-text-sm"
            >
                {{ salable.price_range.price }}
            </span>
            <span
                class="tw-text-gray-400 tw-line-through"
                v-if="salable.price_range.previous_on_sale"
            >
                {{ salable.price_range.previous_on_sale }}
            </span>
            <span
                class="tw-text-gray-400 tw-line-through"
                v-else-if="salable.price < salable.sale_price"
            >
                {{ salable.regular_price_formatted }}
            </span>
        </template>
        <span
            v-else
            class="tw-text-primary tw-text-sm lg:tw-text-xl tw-font-bold"
        >
            {{ salable.wholesale_price_formatted }}
        </span>
    </div>
</template>

<script setup lang="ts">
import type { Salable } from "../models/Salable"
import { useStoreTypeStore } from "~/stores/StoreTypeStore"

const props = defineProps<{
    salable: Salable
}>()

const storeTypeStore = useStoreTypeStore()
const { is_store_wholesaler } = storeToRefs(storeTypeStore)
</script>
