<script setup lang="ts">
import {
    SupportedActionsCartControlEnum,
    SupportedVariantsCartControlEnum,
    type SupportedActionsCartControl,
    type SupportedVariantsCartControl,
} from "~/types/CartControl"
import Loader from "~/components/Loader.vue"
/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    variant: SupportedVariantsCartControl
    disabledColor?: boolean
    withoutShopButton?: boolean
    minValue?: number
    maxValue?: number
    initialValue?: number
    loading?: boolean
    colorLoading?: string
}>()

const emits = defineEmits<{
    (e: "onClickCart", amount: number): void
    (e: "onUpdateAmount", amount: number): void
    (e: "onValidateSubmit"): boolean
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */
const minValue = ref()
const maxValue = ref()
const productAmount = ref()

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(() => {
    minValue.value = generateMinValue()
    productAmount.value = generateInitialValue()
    maxValue.value = generateMaxValue()
})

/**
 *
 *  Functions section
 *
 *
 */

const generateMinValue = () => {
    if (props.minValue && props.minValue >= 0) return props.minValue
    return 0
}

const generateMaxValue = () => {
    if (
        props.maxValue &&
        props.maxValue > minValue.value &&
        props.maxValue > productAmount.value
    )
        return props.maxValue
    return null
}

const generateInitialValue = () => {
    if (
        props.initialValue &&
        props.minValue &&
        props.initialValue > props.minValue
    )
        return props.initialValue
    if (props.initialValue && !props.minValue) return props.initialValue
    if (!props.initialValue && props.minValue && props.minValue > 0)
        return props.minValue
    return 1
}

const getVariantStyles = () => {
    if (props.variant === SupportedVariantsCartControlEnum.primary) {
        return "tw-bg-primary tw-text-white tw-border tw-border-primary-800"
    }

    if (props.variant === SupportedVariantsCartControlEnum.secondary) {
        return `tw-bg-transparent tw-px-8 ${props.disabledColor ? "tw-text-customGray tw-border-customGray" : "tw-text-primary-800 tw-border-primary-800 hover:tw-bg-primary-800 hover:tw-text-white hover:tw-border-none"} tw-border`
    }
}

const onClickCart = () => {
    emits("onClickCart", productAmount.value)
    productAmount.value = minValue.value
}

const updateAmount = (action: SupportedActionsCartControl) => {
    if (action === SupportedActionsCartControlEnum.increment) {
        if (
            props.maxValue &&
            maxValue.value &&
            productAmount.value === maxValue.value
        )
            return null
        productAmount.value++
    }
    if (action === SupportedActionsCartControlEnum.decrement) {
        if (productAmount.value === minValue.value) return null
        productAmount.value--
    }

    emits("onUpdateAmount", productAmount.value)
}

const validateAction = () => {
    const defaultValidateAction = false

    return emits("onValidateSubmit") || defaultValidateAction
}

const onManualUpdate = (value: number) => {
    if (
        (minValue.value && minValue.value <= value) ||
        (maxValue.value && maxValue.value >= value) ||
        (!minValue.value && !maxValue.value)
    ) {
        productAmount.value = value
        emits("onUpdateAmount", productAmount.value)
    }
}

defineExpose({
    productAmount,
})
</script>

<template>
    <div class="tw-flex tw-justify-between tw-gap-4 tw-mt-4 tw-px-0">
        <div
            class="tw-flex tw-w-full tw-max-w-[110px] tw-h-11 tw-border tw-rounded-full tw-border-customGray-200"
        >
            <button
                :class="`${disabledColor ? 'tw-text-customGray' : 'tw-text-primary'} tw-font-bold tw-bg-transparent tw-p-2`"
                :disabled="productAmount === minValue || loading"
                @click="updateAmount('decrement')"
            >
                <q-icon name="fa fa-minus" size="10px" />
            </button>
            <div
                class="tw-w-full tw-border-x tw-overflow-x-auto tw-border-customGray-200 tw-p-0 tw-m-0"
            >
                <input
                    :class="`tw-bg-transparent tw-min-w-[40px] sm:tw-min-w-[50px] tw-w-full tw-h-full tw-text-center ${disabledColor ? 'tw-text-customGray' : 'tw-text-primary-800'}`"
                    v-model="productAmount"
                    @update:model-value="onManualUpdate"
                />
            </div>
            <button
                :class="`${disabledColor ? 'tw-text-customGray' : 'tw-text-primary-800'} tw-text-primary tw-font-bold tw-bg-transparent tw-p-2`"
                :disabled="productAmount === maxValue || loading"
                @click="updateAmount('increment')"
            >
                <q-icon name="fa fa-plus" size="10px" />
            </button>
        </div>

        <button
            v-if="!withoutShopButton"
            @click="onClickCart"
            :class="`${getVariantStyles()} tw-cursor-pointer tw-rounded-full tw-w-full tw-min-w-[50px] tw-max-w-[110px] tw-flex tw-justify-center tw-items-center`"
            :disabled="validateAction()"
        >
            <Loader v-if="loading" :color="colorLoading || 'white'" />
            <q-icon v-else name="fa-solid fa-shopping-cart" size="17px" />
        </button>
    </div>
</template>

<style>
button:not(:hover) > .loader .q-spinner {
    @apply !tw-text-primary-800;
}
button:hover > .loader .q-spinner {
    @apply !tw-text-white;
}
</style>
