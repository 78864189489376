<script setup lang="ts">
import { Product } from "~/models/Product"
import { Carousel, Navigation, Slide } from "vue3-carousel"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    title: string
    subtitle?: string
    items: any[]
    numSkeleton?: number
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const numSkeletonComputed = computed(() => props.numSkeleton || 4)

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
</script>

<template>
    <div
        class="tw-w-full tw-mx-auto tw-py-10 tw-px-2"
        v-if="(items && items.length > 0) || !items || items?.length === 0"
    >
        <div
            class="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-between sm:tw-items-end"
        >
            <div>
                <span
                    v-if="subtitle"
                    class="translateUp tw-text-primary-800 tw-text-xl tw-font-semibold tw-my-10"
                    >{{ subtitle }}</span
                >
                <h5
                    :class="`translateUp tw-text-customGray-700 tw-text-[32px] sm:tw-text-[40px] md:tw-text-[32px] tw-font-semibold ${subtitle ? 'tw-my-2' : 'tw-my-10'}`"
                >
                    {{ title }}
                </h5>
            </div>
            <slot name="actions"></slot>
        </div>
        <div v-if="!items || items?.length === 0">
            <Carousel
                :items-to-show="5"
                :items-to-scroll="1"
                draggable
                :autoplay="3000"
                :transition="500"
                wrap-around
                :breakpoints="{
                    300: {
                        itemsToShow: 1,
                        snapAlign: 'start',
                    },
                    640: {
                        itemsToShow: 2,
                        snapAlign: 'start',
                    },
                    1024: {
                        itemsToShow: 4,
                        snapAlign: 'start',
                    },
                    1280: {
                        itemsToShow: 5,
                        snapAlign: 'start',
                    },
                }"
            >
                <Slide
                    v-for="item in numSkeletonComputed"
                    :key="item"
                    class="tw-p-3 tw-overflow-hidden tw-w-full !tw-h-auto"
                >
                    <slot name="skeleton"></slot>
                </Slide>

                <template #addons>
                    <slot name="addons">
                        <Navigation></Navigation>
                    </slot>
                </template>
            </Carousel>
        </div>

        <template v-else>
            <Carousel
                :items-to-show="5"
                :items-to-scroll="1"
                draggable
                :autoplay="3000"
                :transition="500"
                wrap-around
                :breakpoints="{
                    300: {
                        itemsToShow: 1,
                        snapAlign: 'start',
                    },
                    640: {
                        itemsToShow: 2,
                        snapAlign: 'start',
                    },
                    1024: {
                        itemsToShow: 4,
                        snapAlign: 'start',
                    },
                    1280: {
                        itemsToShow: 5,
                        snapAlign: 'start',
                    },
                }"
            >
                <Slide
                    v-for="(item, i) in items"
                    :key="i"
                    class="tw-p-3 tw-overflow-hidden tw-w-full !tw-h-auto"
                >
                    <slot :item="item"></slot>
                </Slide>

                <template #addons>
                    <slot name="addons">
                        <Navigation></Navigation>
                    </slot>
                </template>
            </Carousel>
        </template>
    </div>
</template>
<style>
.carousel__prev {
    @apply xl:tw-left-[-60px];
}
.carousel__next {
    @apply xl:tw-right-[-60px];
}
</style>
