<script setup lang="ts">
import { useI18n } from "vue-i18n"
import SvgShare from "~/components/SvgShare.vue"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps([
    "rating",
    "total_review",
    "interaction",
    "isUserBuyProduct",
    "size",
])
const emits = defineEmits(["share"])

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const i18n = useI18n()

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */
</script>

<template>
    <div class="tw-flex tw-flex-wrap tw-gap-2 tw-items-center">
        <q-rating
            v-model="props.rating"
            :size="size || '2rem'"
            max="5"
            readonly
        />
        <div class="tw-text-gray-500 tw-font-normal">
            <span>{{
                i18n.t("fields.ratings", { number: props.total_review || 0 })
            }}</span>
        </div>
        <SvgShare
            v-if="interaction"
            class="tw-text-secondary tw-cursor-pointer"
            @click="() => emits('share')"
        />
    </div>
</template>

<style>
.product-rating .material-icons {
    font-size: inherit !important;
}
</style>
