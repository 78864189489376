<script lang="ts" setup>
import {
    SUPPORTED_WARRANTY_VARIANTS_ENUM,
    type SUPPORTED_WARRANTY_VARIANTS,
} from "~/types/WarrantyLabel"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    warranty_text: string | undefined
    variant: SUPPORTED_WARRANTY_VARIANTS
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

const getTextStyle = () => {
    if (props.variant === SUPPORTED_WARRANTY_VARIANTS_ENUM.primary) {
        return "tw-text-[#060726]"
    }
    if (props.variant === SUPPORTED_WARRANTY_VARIANTS_ENUM.secondary) {
        return "tw-text-[#292931]"
    }
    return ""
}

const getBorderStyleVariant = () => {
    if (props.variant === SUPPORTED_WARRANTY_VARIANTS_ENUM.primary) {
        return "tw-border-b-primary-100"
    }
    if (props.variant === SUPPORTED_WARRANTY_VARIANTS_ENUM.secondary) {
        return "tw-border-b-secondary-700"
    }
    return ""
}

const getBgStyleVariant = () => {
    if (props.variant === SUPPORTED_WARRANTY_VARIANTS_ENUM.primary) {
        return "tw-bg-[#060726]"
    }
    if (props.variant === SUPPORTED_WARRANTY_VARIANTS_ENUM.secondary) {
        return "tw-bg-secondary-900"
    }
    return ""
}
</script>
<template>
    <div class="tw-z-[1] tw-absolute tw-top-4 tw-left-3 warranty tw-text-xs">
        <div :class="`warranty-left ${getBgStyleVariant()}`"></div>
        <div
            :class="`tw-w-[147px] tw-h-0 tw-border-x-[25px] tw-border-x-transparent tw-border-b-[25px] ${getBorderStyleVariant()} tw-px-1 tw-overflow-hidden`"
        >
            <div
                class="tw-absolute tw-w-full tw-inset-0 tw-m-auto tw-flex tw-justify-center tw-items-center"
            >
                <span
                    :class="`tw-text-center tw-block tw-font-medium ${getTextStyle()}`"
                    >{{ warranty_text }}</span
                >
            </div>
        </div>
        <div :class="`warranty-right ${getBgStyleVariant()}`"></div>
    </div>
</template>

<style>
.warranty {
    transform: rotate(-45deg) translateX(-28%) translateY(-125%);
    transition: all 0.5s ease;
}

.warranty-left,
.warranty-right {
    content: "";
    width: 10px;
    height: 30px;
    position: absolute;
    top: 0;
    z-index: -100;
}

.warranty-left {
    left: 0;
    transform: rotate(45deg) scale(0.7) translateX(110%);
}
.warranty-right {
    right: 0;
    transform: rotate(-45deg) scaleX(0.7) translateX(-110%) translateY(-15%);
}
</style>
