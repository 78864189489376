<script setup lang="ts">
import { SupportedAnimatedCountDownEnum } from "~/composables/useCountDown"
import { Product } from "~/models/Product"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    product: Product
    fullWidth?: boolean
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const { countdown, countDownEnabled, updateCountDown } = useCountDown(
    props.product
)

const intervalCountDown = setInterval(() => {
    countdown.value = updateCountDown()
}, 1000)

/**
 *
 *  Life cicle events
 *
 *
 */

onBeforeUnmount(() => {
    clearInterval(intervalCountDown)
})

/**
 *
 *  Functions section
 *
 *
 */

const getColor = () => {
    return !props.product?.soon ? "secondary" : "primary"
}
</script>

<template>
    <div
        :class="`countdown tw-flex tw-items-center ${fullWidth ? 'tw-gap-[5px] lg:tw-gap-[2px] tw-max-w-[520px]' : 'tw-gap-[2px]'}`"
        data-test="countdown"
        v-if="product && countDownEnabled"
    >
        <CountDownItem
            :type="SupportedAnimatedCountDownEnum.days"
            :countdownNumber="countdown.days"
            :isAnimated="countdown.isAnimated"
            :fullWidth="fullWidth"
            :color="getColor()"
        />
        <CountDownItem
            :type="SupportedAnimatedCountDownEnum.hours"
            :countdownNumber="countdown.hours"
            :isAnimated="countdown.isAnimated"
            :fullWidth="fullWidth"
            :color="getColor()"
        />
        <CountDownItem
            :type="SupportedAnimatedCountDownEnum.minutes"
            :countdownNumber="countdown.minutes"
            :isAnimated="countdown.isAnimated"
            :fullWidth="fullWidth"
            :color="getColor()"
        />
        <CountDownItem
            :type="SupportedAnimatedCountDownEnum.seconds"
            :countdownNumber="countdown.seconds"
            :isAnimated="countdown.isAnimated"
            :fullWidth="fullWidth"
            :color="getColor()"
        />
    </div>
</template>
