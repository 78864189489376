export enum SupportedActionsCartControlEnum {
    increment = "increment",
    decrement = "decrement",
}

export type SupportedActionsCartControl =
    keyof typeof SupportedActionsCartControlEnum

export enum SupportedVariantsCartControlEnum {
    primary = "primary",
    secondary = "secondary",
}

export type SupportedVariantsCartControl =
    keyof typeof SupportedVariantsCartControlEnum
