import { ref, computed } from "vue"
import { useDefaultSettingsStore } from "~/stores/defaultSettingsStore"

export enum SupportedAnimatedCountDownEnum {
    days = "days",
    hours = "hours",
    minutes = "minutes",
    seconds = "seconds",
    none = "none",
}

export type SupportedAnimatedCountDown =
    keyof typeof SupportedAnimatedCountDownEnum

export interface CountDownType {
    days: number
    hours: number
    minutes: number
    seconds: number
    isAnimated: SupportedAnimatedCountDown
}

export function useCountDown(product: Product) {
    const defaultSettingsStore = useDefaultSettingsStore()

    const today = computed(() =>
        defaultSettingsStore?.settings?.today
            ? new Date(
                  (defaultSettingsStore?.settings?.today as string).replace(
                      " ",
                      "T"
                  ) + "Z"
              )
            : undefined
    )

    const dateInit = computed(() => {
        if (product?.discount) {
            return product?.discount.date_init
                ? new Date(product?.discount.date_init)
                : undefined
        } else if (product?.soon) {
            return product?.date_init_soon
                ? new Date(product?.date_init_soon)
                : undefined
        }
        return undefined
    })
    const dateEnd = computed(() => {
        if (product?.discount) {
            return product?.discount.date_end
                ? new Date(
                      (product?.discount.date_end as unknown as string) +
                          "T00:00:00"
                  )
                : undefined
        } else if (product?.soon) {
            return product?.date_end_soon
                ? new Date(product?.date_end_soon)
                : undefined
        }
        return undefined
    })

    const defaultInitialValues: CountDownType = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        isAnimated: SupportedAnimatedCountDownEnum.none,
    }

    dateEnd.value?.setHours(23, 59, 59, 999)

    const diffMiliseg = computed(() => {
        if (dateEnd.value && today.value) {
            return dateEnd.value - today.value
        }
        return 0
    })

    const segTotal = Math.floor(diffMiliseg.value / 1000)
    const diffDate = ref(Math.floor(segTotal / (60 * 60 * 24)))
    const diffHours = ref(Math.floor((segTotal % (60 * 60 * 24)) / (60 * 60)))
    const diffMinutes = ref(Math.floor((segTotal % (60 * 60)) / 60))
    const diffSeconds = ref(segTotal % 60)

    const countDownEnabled = computed({
        get() {
            return (
                dateInit.value &&
                dateEnd.value &&
                today.value &&
                diffMiliseg.value > 0 &&
                dateInit.value <= today.value &&
                dateEnd.value >= today.value
            )
        },
        set(newValue) {
            return newValue
        },
    })

    const initializeCountDown = (
        initialValues?: CountDownType | undefined
    ): CountDownType => {
        if (initialValues) return initialValues
        return defaultInitialValues
    }

    const updateCountDown = (): CountDownType => {
        if (countDownEnabled.value) {
            let isAnimated = SupportedAnimatedCountDownEnum.seconds
            diffSeconds.value -= 1
            if (diffSeconds.value < 0) {
                diffSeconds.value = 59
                diffMinutes.value -= 1
                isAnimated = SupportedAnimatedCountDownEnum.minutes
                if (diffMinutes.value < 0) {
                    diffMinutes.value = 59
                    diffHours.value -= 1
                    isAnimated = SupportedAnimatedCountDownEnum.hours
                    if (diffHours.value < 0) {
                        diffHours.value = 23
                        diffDate.value -= 1
                        isAnimated = SupportedAnimatedCountDownEnum.days
                        if (diffDate.value < 0) {
                            countDownEnabled.value = false
                            return defaultInitialValues
                        }
                    }
                }
            }

            return {
                days: diffDate.value,
                hours: diffHours.value,
                minutes: diffMinutes.value,
                seconds: diffSeconds.value,
                isAnimated: isAnimated,
            }
        }

        return defaultInitialValues
    }

    const countdown = ref<CountDownType>(initializeCountDown())

    return {
        countdown,
        countDownEnabled,
        updateCountDown,
    }
}
