<script lang="ts" setup>
import { useProductCardData } from "~/composables/ProductCardData"
import type { Product } from "~/models/Product"
import { useCountDown } from "~/composables/useCountDown"
import { capitalizeFirstLetter } from "~/common/helpers"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    product: Product
    withoutLabels?: boolean
}>()

defineEmits<{
    (event: "add", product: Product, quantity: number): void
    (event: "show", product: Product, quantity: number): void
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const { slide, quantity, add, show } = useProductCardData(props.product)

const loading = ref(false)

const { countDownEnabled } = useCountDown(props.product)

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

const getImageSource = (image: any) => {
    if (image && "id" in image) {
        return image.type === "image/avif" ? image.origin : image.thumb
    } else {
        return ""
    }
}

const getImageId = (image: any) => {
    if (image && "id" in image) {
        return image.id
    } else {
        return ""
    }
}

const getImageStyle = (image: string) => {
    const imageObject = new Image()
    imageObject.src = image
    return {
        backgroundSize: "contain",
        backgroundPosition: "center center",
    }
}

const onUpdateAmount = (amount: number) => {
    quantity.value = amount
}

const onAddToCart = (amount: number) => {
    loading.value = true
    add(props.product, amount)
        .then(() => {
            loading.value = false
        })
        .catch(() => {
            loading.value = false
        })
}
const showProduct = () => show(props.product)
</script>
<template>
    <div class="tw-flex tw-flex-col tw-w-full tw-h-full card-product-container">
        <div
            class="tw-bg-white tw-cursor-pointer tw-rounded-[16px] tw-relative tw-border tw-flex tw-flex-col tw-gap-4 tw-justify-between tw-pb-5 tw-h-full"
        >
            <div class="tw-flex tw-flex-col" @click="showProduct">
                <WarrantyLabel
                    variant="primary"
                    :warranty_text="$t('products.soon')"
                    v-if="
                        props.product.soon &&
                        countDownEnabled &&
                        !props.withoutLabels
                    "
                />
                <WarrantyLabel
                    variant="secondary"
                    :warranty_text="$t('products.in_offer')"
                    v-else-if="
                        (product.stock?.in_stock ||
                            product.has_attribute_terms) &&
                        countDownEnabled &&
                        !props.withoutLabels
                    "
                />
                <div
                    class="tw-flex tw-justify-center tw-w-full tw-aspect-product-card-image tw-relative"
                >
                    <q-carousel
                        class="tw-w-full tw-cursor-pointer tw-rounded-tl-[16px] tw-rounded-tr-[16px] tw-h-full card-product-image-container"
                        v-model="slide"
                        infinite
                        autoplay
                        animated
                        transition-next="fade"
                        transition-prev="fade"
                    >
                        <q-carousel-slide
                            v-if="product.images.length === 0"
                            name="-1"
                            img-src="/img/product_placeholder.png"
                            class="card_product_image tw-bg-cover tw-bg-center"
                        />

                        <q-carousel-slide
                            class="card_product_image"
                            v-for="(image, i) in product.images"
                            :key="i"
                            :name="getImageId(image)"
                            :img-src="getImageSource(image)"
                            :style="getImageStyle(image.thumb)"
                        />
                    </q-carousel>
                    <q-icon
                        name="visibility"
                        class="tw-w-5 tw-h-5 tw-absolute tw-top-2 tw-right-5 tw-text-customGray-600 !tw-hidden button_card_product"
                    />
                </div>

                <CountDown
                    :product="product"
                    class="tw-mx-auto"
                    v-if="
                        (product.stock?.in_stock ||
                            product.has_attribute_terms ||
                            props.product.soon) &&
                        countDownEnabled
                    "
                />
            </div>
            <div
                class="tw-px-4 tw-relative tw-flex tw-flex-col tw-gap-4 tw-justify-between tw-h-full"
            >
                <div
                    class="tw-relative tw-flex tw-flex-col tw-gap-4 tw-justify-between"
                    @click="showProduct"
                >
                    <h3 class="tw-text-customGray-300 tw-text-xs tw-self-start">
                        {{
                            (product.categories as Category[])
                                ?.map((i) => capitalizeFirstLetter(i.name))
                                .join(", ")
                        }}
                    </h3>

                    <div class="tw-flex tw-flex-col tw-gap-3 tw-h-full">
                        <p
                            class="tw-text-xl tw-text-primary-800 tw-font-semibold tw-text-start tw-my-0"
                        >
                            {{ capitalizeFirstLetter(product.name) }}
                        </p>
                        <span
                            v-if="
                                product.is_warranty &&
                                product.warranty_formatted
                            "
                            class="tw-text-sm tw-text-primary-400 tw-text-start"
                            >{{ product?.warranty_formatted }}</span
                        >
                        <span
                            v-if="product.brand"
                            class="tw-text-sm lg:tw-text-base tw-text-zinc-600 tw-font-semibold"
                        >
                            {{ product.brand }}</span
                        >
                        <ShowRating
                            :rating="product?.rating_approved"
                            :total_review="product?.reviews_approved"
                            color="secondary"
                            size="1.5rem"
                        />
                        <ProductPrice :product="product" />
                    </div>
                </div>

                <CartControl
                    v-if="
                        product.stock?.in_stock && !product.has_attribute_terms
                    "
                    :minValue="1"
                    variant="secondary"
                    @onClickCart="onAddToCart"
                    @onUpdateAmount="onUpdateAmount"
                    :disabledColor="
                        !product.stock?.in_stock && !product.has_attribute_terms
                    "
                    :loading="loading"
                />
                <CartControl
                    v-else
                    variant="secondary"
                    @onClickCart="showProduct"
                    @onUpdateAmount="onUpdateAmount"
                    :minValue="1"
                    :loading="loading"
                />
            </div>
        </div>
    </div>
</template>
<style>
.card_product_image {
    @apply tw-bg-no-repeat tw-aspect-product-card-image tw-relative;
}

.card-product-container:hover .button_card_product {
    @apply !tw-inline hover:tw-text-primary;
    transition: all 0.2s ease;
}

.button_card_product:hover {
    transform: scale(0.95);
    transition: all 0.2s ease;
}

.card-product-container:hover .q-carousel__slides-container .q-panel {
    overflow: hidden;
}

.card-product-container:hover .card_product_image {
    transform: scale(1.1);
    transition: all 0.5s ease;
    transform-origin: center center;
}
</style>
