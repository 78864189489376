<script setup lang="ts">
import {
    SupportedAnimatedCountDown,
    SupportedAnimatedCountDownEnum,
} from "~/composables/useCountDown"

/**
 *
 *  Props and emits section
 *
 *
 */

const props = defineProps<{
    countdownNumber: number
    type: SupportedAnimatedCountDown
    isAnimated: SupportedAnimatedCountDown
    fullWidth?: boolean
    color: "primary" | "secondary"
}>()

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

/**
 *
 *  Life cicle events
 *
 *
 */

/**
 *
 *  Functions section
 *
 *
 */

const getType = () => {
    if (props.type === SupportedAnimatedCountDownEnum.days) {
        return "días"
    } else if (props.type === SupportedAnimatedCountDownEnum.hours) {
        return "horas"
    } else if (props.type === SupportedAnimatedCountDownEnum.minutes) {
        return "minutos"
    } else if (props.type === SupportedAnimatedCountDownEnum.seconds) {
        return "segundos"
    }
    return ""
}

const isAnimate = () => {
    if (
        props.type === props.isAnimated &&
        props.type === SupportedAnimatedCountDownEnum.seconds
    ) {
        return true
    }
    if (
        props.isAnimated === SupportedAnimatedCountDownEnum.minutes &&
        (props.type === SupportedAnimatedCountDownEnum.minutes ||
            props.type === SupportedAnimatedCountDownEnum.seconds)
    ) {
        return true
    }
    if (
        props.isAnimated === SupportedAnimatedCountDownEnum.hours &&
        (props.type === SupportedAnimatedCountDownEnum.hours ||
            props.type === SupportedAnimatedCountDownEnum.minutes ||
            props.type === SupportedAnimatedCountDownEnum.seconds)
    ) {
        return true
    }
    if (
        props.isAnimated === SupportedAnimatedCountDownEnum.days &&
        (props.type === SupportedAnimatedCountDownEnum.days ||
            props.type === SupportedAnimatedCountDownEnum.hours ||
            props.type === SupportedAnimatedCountDownEnum.minutes ||
            props.type === SupportedAnimatedCountDownEnum.seconds)
    ) {
        return true
    }
}
</script>

<template>
    <div
        :class="[
            'tw-flex tw-flex-col tw-flex-wrap tw-rounded-[5.25px] tw-items-center tw-overflow-hidden tw-text-white tw-px-3 tw-py-1',
            color === 'primary' ? 'tw-bg-[#060726]' : 'tw-bg-[#060726]',
        ]"
    >
        <span
            :class="`${fullWidth ? 'tw-text-3xl sm:tw-text-[28px]' : 'tw-text-sm'} tw-font-poppins tw-font-bold ${isAnimate() && 'countdown-item'}`"
            >{{
                countdownNumber > 9 ? countdownNumber : "0" + countdownNumber
            }}</span
        >
        <span
            :class="`${fullWidth ? 'tw-text-xl sm:tw-text-[20px]' : 'tw-text-[10px]'} tw-font-poppins tw-font-medium`"
            >{{ getType() }}</span
        >
    </div>
</template>

<style>
@keyframes pulse {
    from {
        transform: scale(1);
    }
    50% {
        transform: scale(0.6);
    }
    to {
        transform: scale(1);
    }
}
.countdown-item {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}
</style>
