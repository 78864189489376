<script>
export default {
    name: "FlowbiteShareAllOutline",
}
</script>
<template>
    <span class="tw-text-secondary tw-cursor-pointer">
        <q-tooltip> Compartir producto </q-tooltip>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
        >
            <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m15.141 6l5.518 4.95a1.05 1.05 0 0 1 0 1.549l-5.612 5.088m-6.154-3.214v1.615a.95.95 0 0 0 1.525.845l5.108-4.251a1.1 1.1 0 0 0 0-1.646l-5.108-4.251a.95.95 0 0 0-1.525.846v1.7c-3.312 0-6 2.979-6 6.654v1.329a.7.7 0 0 0 1.344.353a5.174 5.174 0 0 1 4.652-3.191z"
            />
        </svg>
    </span>
</template>
